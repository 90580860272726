import React, { forwardRef } from "react";

import Tippy, { TippyProps } from "@tippyjs/react";
import cx from "classnames";
import { noop } from "lodash";
import { roundArrow } from "tippy.js";
import "tippy.js/dist/svg-arrow.css";
import "tippy.js/dist/tippy.css";

import { useCurrentFullscreenElement } from "~/components/common/Fullscreen";
import { __isClosingModal } from "~/ui/modals";

import styles from "./Tooltip.module.css";

// if a modal has been closed, the tooltip should not show on re-focus of the input
function onShowToolTip(): false | void {
	if (__isClosingModal) {
		return false;
	}
	return;
}

/**
 * For dismissible or hover tooltips.
 */
const Tooltip = forwardRef<Element, React.ComponentProps<typeof Tippy>>(function Tooltip(
	{ className, onShow: parentOnShow, onMount, appendTo, ...rest },
	ref,
) {
	const onShow = React.useCallback(
		(...args: Parameters<NonNullable<TippyProps["onShow"]>>) => {
			onShowToolTip();
			return parentOnShow?.(...args);
		},
		[parentOnShow],
	);
	const currentFullscreenElement = useCurrentFullscreenElement();
	const appendToValue = appendTo ?? currentFullscreenElement ?? undefined;
	return (
		<Tippy
			arrow={roundArrow}
			{...rest}
			ref={ref}
			className={cx(className, styles.tippy)}
			onShow={onShow}
			// Fall back to noop because Tippy calls onMount even if it's undefined
			onMount={onMount ?? noop}
			{...(appendToValue ? { appendTo: appendToValue } : {})}
		/>
	);
});

export { useSingleton } from "@tippyjs/react";
export default Tooltip;
export type { TippyProps as TooltipProps };
