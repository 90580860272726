/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type ResponseStatusChoices = "accepted" | "declined" | "needsAction" | "none" | "notResponded" | "organizer" | "tentative" | "tentativelyAccepted";
export type CalendarPageParticipantEventsQueryVariables = {
    participantEmail: string;
    participantId?: string | null | undefined;
    start: string;
    end: string;
};
export type CalendarPageParticipantEventsQueryResponse = {
    readonly viewer: {
        readonly eventsByUser: {
            readonly edges: ReadonlyArray<{
                readonly node: {
                    readonly title: string | null;
                    readonly start: string;
                    readonly end: string;
                    readonly isAllDay: boolean;
                    readonly userResponseStatus: ResponseStatusChoices | null;
                } | null;
            } | null>;
        } | null;
    };
};
export type CalendarPageParticipantEventsQuery = {
    readonly response: CalendarPageParticipantEventsQueryResponse;
    readonly variables: CalendarPageParticipantEventsQueryVariables;
};



/*
query CalendarPageParticipantEventsQuery(
  $participantEmail: String!
  $participantId: ID
  $start: DateTime!
  $end: DateTime!
) {
  viewer {
    eventsByUser(participantEmail: $participantEmail, participantId: $participantId, start: $start, end: $end) {
      edges {
        node {
          title
          start
          end
          isAllDay
          userResponseStatus
        }
      }
    }
    id
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "end"
    } as any, v1 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "participantEmail"
    } as any, v2 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "participantId"
    } as any, v3 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "start"
    } as any, v4 = {
        "alias": null,
        "args": [
            {
                "kind": "Variable",
                "name": "end",
                "variableName": "end"
            },
            {
                "kind": "Variable",
                "name": "participantEmail",
                "variableName": "participantEmail"
            },
            {
                "kind": "Variable",
                "name": "participantId",
                "variableName": "participantId"
            },
            {
                "kind": "Variable",
                "name": "start",
                "variableName": "start"
            }
        ],
        "concreteType": "BusyTimeSlotConnection",
        "kind": "LinkedField",
        "name": "eventsByUser",
        "plural": false,
        "selections": [
            {
                "alias": null,
                "args": null,
                "concreteType": "BusyTimeSlotEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "BusyTimeSlot",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "title",
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "start",
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "end",
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "isAllDay",
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "userResponseStatus",
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    }
                ],
                "storageKey": null
            }
        ],
        "storageKey": null
    } as any;
    return {
        "fragment": {
            "argumentDefinitions": [
                (v0 /*: any*/),
                (v1 /*: any*/),
                (v2 /*: any*/),
                (v3 /*: any*/)
            ],
            "kind": "Fragment",
            "metadata": null,
            "name": "CalendarPageParticipantEventsQuery",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Viewer",
                    "kind": "LinkedField",
                    "name": "viewer",
                    "plural": false,
                    "selections": [
                        (v4 /*: any*/)
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [
                (v1 /*: any*/),
                (v2 /*: any*/),
                (v3 /*: any*/),
                (v0 /*: any*/)
            ],
            "kind": "Operation",
            "name": "CalendarPageParticipantEventsQuery",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Viewer",
                    "kind": "LinkedField",
                    "name": "viewer",
                    "plural": false,
                    "selections": [
                        (v4 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "f224e099c3999162a94f9f19e41a42fe",
            "id": null,
            "metadata": {},
            "name": "CalendarPageParticipantEventsQuery",
            "operationKind": "query",
            "text": "query CalendarPageParticipantEventsQuery(\n  $participantEmail: String!\n  $participantId: ID\n  $start: DateTime!\n  $end: DateTime!\n) {\n  viewer {\n    eventsByUser(participantEmail: $participantEmail, participantId: $participantId, start: $start, end: $end) {\n      edges {\n        node {\n          title\n          start\n          end\n          isAllDay\n          userResponseStatus\n        }\n      }\n    }\n    id\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = '201347f54820f4508531361d32d6a14d';
export default node;
