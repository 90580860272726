import React from "react";
import { toast, Toast, ToastOptions } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { faTimes } from "@fortawesome/pro-light-svg-icons";
import cx from "classnames";
import { noop } from "lodash";

import IconButton from "~/components/fellow/IconButton";
import Button, { ButtonProps } from "~/ui/button";

import styles from "~/ui/toast/toast.global.module.css";

export type ToastId = number | string;

const DEFAULT_POSITION = "bottom-center";
export const VARIANTS = ["success", "warn", "info", "error"] as const;

type Variant = (typeof VARIANTS)[number];

export interface ToastyOptions extends ToastOptions {
	title?: React.ReactNode;
	actions?: React.ReactNode;
	noBorder?: boolean;
}

type ToastyPrompt = (content: React.ReactNode, opt?: ToastyOptions) => ToastId;

type UpdateOptions = Parameters<(typeof toast)["update"]>[1] & {
	actions?: React.ReactNode;
	title?: React.ReactNode;
	noBorder?: boolean;
	type: Variant;
};

type Toasty = Omit<Toast, Variant | "update"> & {
	toastify: Toast;
	update: (toastId: ToastId, options: UpdateOptions) => void;
} & {
	[k in Variant]: ToastyPrompt;
};

export const toasty: Toasty = Object.assign({ toastify: toast }, toast);
toasty.isActive = (id: ToastId): boolean => {
	return toasty.toastify.isActive(id);
};
toasty.dismiss = (id: ToastId | undefined): void => {
	toasty.toastify.dismiss(id);
};

const makeContent = (content: React.ReactNode, title?: React.ReactNode, actions?: React.ReactNode) => {
	return (
		<div className={cx(styles.toastBody, "force-dark-theme")}>
			<div className={styles.bodyWrapper}>
				{title && <div className={styles.bodyTitle}>{title}</div>}
				<div className={styles.bodyContent}>{content}</div>
			</div>
			{actions && <div className={styles.bodyActions}>{actions}</div>}
		</div>
	);
};

const promptStyles = {
	success: styles.toastSuccess,
	warn: styles.toastWarning,
	info: styles.toastInfo,
	error: styles.toastError,
} as const;

function makeClasses(method: Variant, extraClasses: string | object | undefined, noBorder?: boolean): string {
	return cx(styles.toast, promptStyles[method], extraClasses, {
		[styles.toastNoBorder]: noBorder,
	});
}

function ToastCloseButton({ closeToast }: { closeToast: () => void }) {
	return <IconButton icon={faTimes} title="Dismiss" className={styles.closeButton} onClick={closeToast} />;
}

/**
 * Convert the `closeButton` option from our toast functions to one suitable
 * for passing to react-toastify.
 *
 * In particular, this means defaulting to _no_ close button (if the option
 * value is undefined), and supplying our own implementation if the
 * `closeButton` option value is true. If the option value is neither true nor
 * undefined, or if this is an old-style toast, we just pass it through
 * unchanged.
 *
 * @param optionValue The value passed as `closeButton` in the toast options
 * object.
 */
function translateCloseButtonOption(optionValue: boolean | undefined | React.ReactNode): boolean | React.ReactNode {
	if (optionValue === true) {
		// react-toastify overrides the props passed to this component:
		// eslint-disable-next-line max-len
		// https://github.com/fkhadra/react-toastify/blob/751b332aa989d5aefe751e8439dbd8884182930f/src/components/Toast.tsx#L62
		return <ToastCloseButton closeToast={noop} />;
	}
	if (optionValue === undefined) {
		// hide the button by default... We've been doing so already (through css)
		// so a bunch of places assume there to be no close button. If we enable it
		// by default we'll have a bunch of broken-looking toasts.
		return false;
	}
	return optionValue;
}

VARIANTS.forEach(method => {
	const prompt = (
		content: React.ReactNode,
		{ title, actions, noBorder, closeButton, ...options }: ToastyOptions = {},
	): ToastId => {
		return toast[method](makeContent(content, title, actions), {
			position: DEFAULT_POSITION,
			...options,
			className: makeClasses(method, options.className, noBorder),
			closeButton: translateCloseButtonOption(closeButton),
		});
	};
	toasty[method] = prompt;
});

toasty.update = function update(
	toastId: Parameters<(typeof toast)["update"]>[0],
	{ actions, title, render, type, className, noBorder, ...data }: UpdateOptions,
): void {
	toast.update(toastId, {
		render: makeContent(render, title, actions),
		className: makeClasses(type, className, noBorder),
		type,
		...data,
	});
};

// Configure the ToastContainer. When `toast.method` is called for the first time, the container will be mounted.
// Once a ToastContainer is rendered, subsequent calls to configure will have no effect
toasty.configure({
	position: "bottom-center",
	className: "toast-container",
	toastClassName: "toast",
	autoClose: 5000,
	newestOnTop: false,
	hideProgressBar: true,
	closeOnClick: true,
	pauseOnHover: true,
});

export const ToastActionButton = React.forwardRef<HTMLButtonElement, ButtonProps>(
	({ className, variant = "primary", ...props }, ref) => {
		const classNames = cx(className, styles.toastActionButton);
		return <Button {...props} variant={variant} className={classNames} ref={ref} size="normal" />;
	},
);

ToastActionButton.displayName = "ToastActionButton";

export default toasty;
