/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type utils_publicGetClipLinkQueryVariables = {
    clipId: string;
};
export type utils_publicGetClipLinkQueryResponse = {
    readonly node: ({
        readonly __typename: "MeetingRecordingClip";
        readonly id: string;
        readonly url: string;
    } | {
        /*This will never be '%other', but we need some
        value in case none of the concrete values match.*/
        readonly __typename: "%other";
    }) | null;
};
export type utils_publicGetClipLinkQuery = {
    readonly response: utils_publicGetClipLinkQueryResponse;
    readonly variables: utils_publicGetClipLinkQueryVariables;
};



/*
query utils_publicGetClipLinkQuery(
  $clipId: ID!
) {
  node(id: $clipId) {
    __typename
    ... on MeetingRecordingClip {
      id
      url(forCopy: true)
    }
    id
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "clipId"
        } as any
    ], v1 = [
        {
            "kind": "Variable",
            "name": "id",
            "variableName": "clipId"
        } as any
    ], v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
    } as any, v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any, v4 = {
        "alias": null,
        "args": [
            {
                "kind": "Literal",
                "name": "forCopy",
                "value": true
            }
        ],
        "kind": "ScalarField",
        "name": "url",
        "storageKey": "url(forCopy:true)"
    } as any;
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "utils_publicGetClipLinkQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/),
                        {
                            "kind": "InlineFragment",
                            "selections": [
                                (v3 /*: any*/),
                                (v4 /*: any*/)
                            ],
                            "type": "MeetingRecordingClip",
                            "abstractKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "utils_publicGetClipLinkQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/),
                        (v3 /*: any*/),
                        {
                            "kind": "InlineFragment",
                            "selections": [
                                (v4 /*: any*/)
                            ],
                            "type": "MeetingRecordingClip",
                            "abstractKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "1c991f4d9917fc7242b750e922e212dc",
            "id": null,
            "metadata": {},
            "name": "utils_publicGetClipLinkQuery",
            "operationKind": "query",
            "text": "query utils_publicGetClipLinkQuery(\n  $clipId: ID!\n) {\n  node(id: $clipId) {\n    __typename\n    ... on MeetingRecordingClip {\n      id\n      url(forCopy: true)\n    }\n    id\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = '0a861e0bc397250e69b803309ea822d1';
export default node;
