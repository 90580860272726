import React from "react";
import FocusLock from "react-focus-lock";

import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { DialogContent, DialogOverlay } from "@reach/dialog";
import "@reach/dialog/styles.css";
import cx from "classnames";
import hoistNonReactStatic from "hoist-non-react-statics";

import ViewportContext from "~/components/common/ViewportContext";
import IconButton from "~/components/fellow/IconButton";

import styles from "./css/Modal.module.less";

type DivProps = React.HTMLProps<HTMLDivElement>;
type HeadingProps = React.HTMLProps<HTMLHeadingElement>;
type ElementProps = React.HTMLProps<HTMLElement>;

type HeaderActionsProps = React.PropsWithChildren<DivProps> & {
	onClose?: React.MouseEventHandler<HTMLButtonElement>;
	closeButtonClassName?: string;
};

/**
 * To be used if the Modal Header requires any additional actions (eg. Information).
 * If this static is used remember to remove onClose from the <Modal.Header>.
 */
export function HeaderActions({
	children,
	onClose,
	className,
	closeButtonClassName,
	...rest
}: HeaderActionsProps): React.ReactElement {
	return (
		<div className={cx(styles.headerActions, className)} {...rest}>
			{children}
			{onClose && (
				<IconButton
					title="Close"
					icon={faTimes}
					className={cx(styles.iconButton, closeButtonClassName)}
					onClick={onClose}
					data-test-id="modal-header-close-button"
				/>
			)}
		</div>
	);
}
HeaderActions.displayName = "Modal.HeaderActions";

type TitleProps = React.PropsWithChildren<HeadingProps>;

export function Title({ children, className, ...rest }: TitleProps): React.ReactElement {
	return (
		<h1 className={cx(styles.headerTitle, className)} {...rest}>
			{children}
		</h1>
	);
}
Title.displayName = "Modal.Title";

export type HeaderProps = {
	onClose?: React.MouseEventHandler<HTMLButtonElement>;
	noBottomBorder?: boolean;
};

export function Header({
	children,
	className,
	noBottomBorder,
	onClose,
	...rest
}: React.PropsWithChildren<ElementProps> & HeaderProps) {
	return (
		<header className={cx(styles.header, { [styles.headerBorder]: !noBottomBorder }, className)} {...rest}>
			{children}
			{onClose && (
				<div className={styles.headerActions}>
					<IconButton
						title="Close"
						icon={faTimes}
						className={styles.iconButton}
						onClick={onClose}
						data-test-id="modal-header-close-button"
					/>
				</div>
			)}
		</header>
	);
}
Header.displayName = "Modal.Header";

export type BodyProps = {
	dark?: boolean;
	noPadding?: boolean;
	/** Only reduces upper padding */
	textOnly?: boolean;
};

export const Body = React.forwardRef<HTMLElement, React.PropsWithChildren<ElementProps> & BodyProps>(
	({ children, className, dark, noPadding, textOnly, ...rest }, ref) => {
		return (
			<main
				className={cx(
					styles.main,
					{ [styles.mainNoPadding]: noPadding, [styles.mainTextOnly]: textOnly, [styles.darkMain]: dark },
					className,
				)}
				ref={ref}
				{...rest}
			>
				{children}
			</main>
		);
	},
);
Body.displayName = "Modal.Body";

type FooterSecondaryActions = React.PropsWithChildren<DivProps>;

export function FooterSecondaryActions({ children, className, ...rest }: FooterSecondaryActions): React.ReactElement {
	return (
		<div className={cx(styles.footerSecondaryActions, className)} {...rest}>
			{children}
		</div>
	);
}
FooterSecondaryActions.displayName = "Modal.FooterSecondaryActions";

type FooterProps = React.PropsWithChildren<ElementProps> & {
	noTopBorder?: boolean;
};

export function Footer({ children, className, noTopBorder, ...rest }: FooterProps) {
	return (
		<footer className={cx(styles.footer, className, { [styles.footerBorder]: !noTopBorder })} {...rest}>
			{children}
		</footer>
	);
}
Footer.displayName = "Modal.Footer";

const animationDuration = window.Cypress ? 0 : 400;

declare module "csstype" {
	interface Properties {
		"--modal-width"?: string;
		/** The default height of a modal */
		"--modal-height"?: string;
	}
}

export type ModalProps = Omit<React.ComponentPropsWithoutRef<typeof DialogOverlay>, "onDismiss"> & {
	onClose: () => void;
	/** Sets the width of a Modal on desktop devices*/
	width?: string;
	/** Sets the default height of a Modal */
	height?: string;
	innerRef?: React.Ref<HTMLDivElement>;
	// We use this to tell focus lock to ignore a node if it contains a class included in this array
	// We need to ignore nodes of inputs inside popups that are rendered inside a modal
	// Otherwise they don't behave properly. That being said there might be a better way to achieve this
	lockIgnoredClasses?: Array<string>;
	lockIgnoredAttrs?: Array<string>;
	overlayClassName?: string;
	disableOutsideClick?: boolean;
};

type ModalState = {
	closing: boolean;
	isOpen: boolean | undefined;
};

class Modal extends React.Component<ModalProps, ModalState> {
	static Header = Header;
	static Title = Title;
	static HeaderActions = HeaderActions;
	static Body = Body;
	static Footer = Footer;
	static FooterSecondaryActions = FooterSecondaryActions;

	static contextType = ViewportContext;
	declare context: React.ContextType<typeof ViewportContext>;

	constructor(props: ModalProps) {
		super(props);

		this.state = {
			closing: false,
			isOpen: props.isOpen,
		};
	}

	/**
	 * Since moving the initial focus is handled by the focus lock, and we disable the automatic
	 * Focus Lock provided by Reach, we have to provide this ourselves. This is mimicking their
	 * implementation as directly as possible.
	 * @see https://github.com/reach/reach-ui/blob/d0fd3fb611b37c7e22efb0681f68b52ce651e28c/packages/dialog/src/index.tsx#L163
	 */
	activateFocusLock = () => {
		if (this.props.initialFocusRef && this.props.initialFocusRef.current) {
			this.props.initialFocusRef.current.focus();
		}
	};

	componentDidUpdate(prevProps: ModalProps) {
		const { closing } = this.state;
		const { isOpen } = this.props;

		if (closing) {
			// Do not switch the state while closing
			return;
		}

		if (prevProps.isOpen !== isOpen) {
			if (!isOpen && this.context.isMobile) {
				// Delayed close on the mobile
				// eslint-disable-next-line react/no-did-update-set-state
				this.setState({ closing: true }, () => {
					if (document.activeElement) {
						const activeInput = document.activeElement as HTMLInputElement;
						if (typeof activeInput.blur === "function") {
							activeInput.blur();
						}
					}

					setTimeout(() => {
						this.setState({ closing: false, isOpen: false });
					}, animationDuration);
				});
			} else {
				// eslint-disable-next-line react/no-did-update-set-state
				this.setState({ isOpen: isOpen });
			}
		}
	}

	onAnimationEnd = (event: React.AnimationEvent<HTMLDivElement>) => {
		event.currentTarget.style.overflow = "auto";
	};

	onClose = () => this.props.onClose();

	isWhitelisted = (node: HTMLElement) => {
		// This allows for an element to have the attribute "data-ignore-focus-lock" and have it and all its children ignored
		if (node.closest("[data-ignore-focus-lock]") != null) return false;

		// This method and its uses could likely be refactored to use the method above
		const { lockIgnoredClasses, lockIgnoredAttrs } = this.props;
		let containsIgnoredClass = false;
		let containsIgnoredAttr = false;
		if (lockIgnoredClasses) {
			containsIgnoredClass = lockIgnoredClasses.some(lockIgnoredClass => node.className.includes(lockIgnoredClass));
		}
		if (lockIgnoredAttrs) {
			containsIgnoredAttr = lockIgnoredAttrs.some(lockIgnoredAttr => node.hasAttribute(lockIgnoredAttr));
		}
		return node.nodeName !== "IFRAME" && !containsIgnoredClass && !containsIgnoredAttr;
	};

	render() {
		const { closing, isOpen } = this.state;
		const {
			className,
			children,
			width,
			height,
			isOpen: _isOpen,
			innerRef,
			lockIgnoredAttrs: _lockIgnoredAttrs,
			overlayClassName,
			disableOutsideClick,
			...rest
		} = this.props;

		const animationClasses = cx({
			[styles.closing]: closing,
		});

		return (
			<DialogOverlay
				dangerouslyBypassFocusLock // without this, users can't type on Intercom
				isOpen={isOpen}
				onDismiss={disableOutsideClick ? undefined : this.onClose}
				className={cx(styles.overlay, overlayClassName, animationClasses)}
				onAnimationEnd={this.onAnimationEnd}
				{...rest}
			>
				<FocusLock
					autoFocus
					returnFocus
					onActivation={this.activateFocusLock}
					whiteList={this.isWhitelisted}
					className={styles.focusLock}
				>
					<DialogContent
						aria-label="dialog-label"
						ref={innerRef}
						className={cx(styles.modal, className, animationClasses)}
						style={{ "--modal-width": width ? width : "31.25rem", "--modal-height": height }}
					>
						{children}
					</DialogContent>
				</FocusLock>
			</DialogOverlay>
		);
	}
}

const forwardedModal = hoistNonReactStatic(
	React.forwardRef<HTMLDivElement, Omit<ModalProps, "innerRef">>((props, ref) => <Modal innerRef={ref} {...props} />),
	Modal,
);
forwardedModal.displayName = "Modal";

export default forwardedModal;
