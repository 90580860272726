/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type ToggleBlockFieldField = "DONE" | "WONT_DO";
export type ToggleBlockFieldInput = {
    noteBlockId?: string | null | undefined;
    noteId?: string | null | undefined;
    noteBlockKey?: string | null | undefined;
    field: ToggleBlockFieldField;
    value: boolean;
    clientMutationId?: string | null | undefined;
};
export type mutationsToggleBlockFieldMutationVariables = {
    input: ToggleBlockFieldInput;
};
export type mutationsToggleBlockFieldMutationResponse = {
    readonly toggleBlockField: {
        readonly ok: boolean;
        readonly noteBlockEdge: {
            readonly node: {
                readonly id: string;
                readonly updatedAt: string | null;
                readonly statusChangedAt: string | null;
            } | null;
        } | null;
    } | null;
};
export type mutationsToggleBlockFieldMutation = {
    readonly response: mutationsToggleBlockFieldMutationResponse;
    readonly variables: mutationsToggleBlockFieldMutationVariables;
};



/*
mutation mutationsToggleBlockFieldMutation(
  $input: ToggleBlockFieldInput!
) {
  toggleBlockField(input: $input) {
    ok
    noteBlockEdge {
      node {
        id
        updatedAt
        statusChangedAt
      }
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "input"
        } as any
    ], v1 = [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "input",
                    "variableName": "input"
                }
            ],
            "concreteType": "ToggleBlockFieldPayload",
            "kind": "LinkedField",
            "name": "toggleBlockField",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "ok",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "NoteBlockEdge",
                    "kind": "LinkedField",
                    "name": "noteBlockEdge",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "NoteBlock",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "id",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "updatedAt",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "statusChangedAt",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "storageKey": null
        } as any
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "mutationsToggleBlockFieldMutation",
            "selections": (v1 /*: any*/),
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "mutationsToggleBlockFieldMutation",
            "selections": (v1 /*: any*/)
        },
        "params": {
            "cacheID": "d496880d70bdf173bb8405231d4d0dcb",
            "id": null,
            "metadata": {},
            "name": "mutationsToggleBlockFieldMutation",
            "operationKind": "mutation",
            "text": "mutation mutationsToggleBlockFieldMutation(\n  $input: ToggleBlockFieldInput!\n) {\n  toggleBlockField(input: $input) {\n    ok\n    noteBlockEdge {\n      node {\n        id\n        updatedAt\n        statusChangedAt\n      }\n    }\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = '4182d049095b4f2c98a36168d6e6c1ff';
export default node;
