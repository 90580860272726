/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type UserType = "ADMIN" | "BOT" | "GUEST" | "MEMBER";
export type authContextQueryVariables = {};
export type authContextQueryResponse = {
    readonly viewer: {
        readonly user: {
            readonly type: UserType;
        };
    };
};
export type authContextQuery = {
    readonly response: authContextQueryResponse;
    readonly variables: authContextQueryVariables;
};



/*
query authContextQuery {
  viewer {
    user {
      type
      id
    }
    id
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "type",
        "storageKey": null
    } as any, v1 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any;
    return {
        "fragment": {
            "argumentDefinitions": [],
            "kind": "Fragment",
            "metadata": null,
            "name": "authContextQuery",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Viewer",
                    "kind": "LinkedField",
                    "name": "viewer",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "User",
                            "kind": "LinkedField",
                            "name": "user",
                            "plural": false,
                            "selections": [
                                (v0 /*: any*/)
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [],
            "kind": "Operation",
            "name": "authContextQuery",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Viewer",
                    "kind": "LinkedField",
                    "name": "viewer",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "User",
                            "kind": "LinkedField",
                            "name": "user",
                            "plural": false,
                            "selections": [
                                (v0 /*: any*/),
                                (v1 /*: any*/)
                            ],
                            "storageKey": null
                        },
                        (v1 /*: any*/)
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "e4aba30deb48740c01ba5561d07666f7",
            "id": null,
            "metadata": {},
            "name": "authContextQuery",
            "operationKind": "query",
            "text": "query authContextQuery {\n  viewer {\n    user {\n      type\n      id\n    }\n    id\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = '3351057a2dcd4a5bdd52910ab73c0094';
export default node;
