/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type ToggleBlockFieldField = "DONE" | "WONT_DO";
export type ToggleBlocksFieldInput = {
    ids?: Array<BlockID> | null | undefined;
    field: ToggleBlockFieldField;
    value: boolean;
    fromTrayApp?: boolean | null | undefined;
    clientMutationId?: string | null | undefined;
};
export type BlockID = {
    id?: string | null | undefined;
    noteId?: string | null | undefined;
    blockKey?: string | null | undefined;
};
export type mutationsToggleBlocksFieldMutationVariables = {
    input: ToggleBlocksFieldInput;
};
export type mutationsToggleBlocksFieldMutationResponse = {
    readonly toggleBlocksField: {
        readonly noteBlockEdges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly updatedAt: string | null;
                readonly statusChangedAt: string | null;
            } | null;
        }>;
    } | null;
};
export type mutationsToggleBlocksFieldMutation = {
    readonly response: mutationsToggleBlocksFieldMutationResponse;
    readonly variables: mutationsToggleBlocksFieldMutationVariables;
};



/*
mutation mutationsToggleBlocksFieldMutation(
  $input: ToggleBlocksFieldInput!
) {
  toggleBlocksField(input: $input) {
    noteBlockEdges {
      node {
        id
        updatedAt
        statusChangedAt
      }
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "input"
        } as any
    ], v1 = [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "input",
                    "variableName": "input"
                }
            ],
            "concreteType": "ToggleBlocksFieldPayload",
            "kind": "LinkedField",
            "name": "toggleBlocksField",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "NoteBlockEdge",
                    "kind": "LinkedField",
                    "name": "noteBlockEdges",
                    "plural": true,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "NoteBlock",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "id",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "updatedAt",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "statusChangedAt",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "storageKey": null
        } as any
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "mutationsToggleBlocksFieldMutation",
            "selections": (v1 /*: any*/),
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "mutationsToggleBlocksFieldMutation",
            "selections": (v1 /*: any*/)
        },
        "params": {
            "cacheID": "1851618e20bb7ef58abca5ae2765d787",
            "id": null,
            "metadata": {},
            "name": "mutationsToggleBlocksFieldMutation",
            "operationKind": "mutation",
            "text": "mutation mutationsToggleBlocksFieldMutation(\n  $input: ToggleBlocksFieldInput!\n) {\n  toggleBlocksField(input: $input) {\n    noteBlockEdges {\n      node {\n        id\n        updatedAt\n        statusChangedAt\n      }\n    }\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = 'e9cda59ec211b2c3123738d6e831bb57';
export default node;
