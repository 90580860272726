/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type ToggleBlockFieldField = "DONE" | "WONT_DO";
export type ToggleBlockFieldInput = {
    noteBlockId?: string | null | undefined;
    noteId?: string | null | undefined;
    noteBlockKey?: string | null | undefined;
    field: ToggleBlockFieldField;
    value: boolean;
    clientMutationId?: string | null | undefined;
};
export type assignableToggleFieldDoneMutationVariables = {
    input: ToggleBlockFieldInput;
};
export type assignableToggleFieldDoneMutationResponse = {
    readonly toggleBlockField: {
        readonly ok: boolean;
    } | null;
};
export type assignableToggleFieldDoneMutation = {
    readonly response: assignableToggleFieldDoneMutationResponse;
    readonly variables: assignableToggleFieldDoneMutationVariables;
};



/*
mutation assignableToggleFieldDoneMutation(
  $input: ToggleBlockFieldInput!
) {
  toggleBlockField(input: $input) {
    ok
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "input"
        } as any
    ], v1 = [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "input",
                    "variableName": "input"
                }
            ],
            "concreteType": "ToggleBlockFieldPayload",
            "kind": "LinkedField",
            "name": "toggleBlockField",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "ok",
                    "storageKey": null
                }
            ],
            "storageKey": null
        } as any
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "assignableToggleFieldDoneMutation",
            "selections": (v1 /*: any*/),
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "assignableToggleFieldDoneMutation",
            "selections": (v1 /*: any*/)
        },
        "params": {
            "cacheID": "741b2eb38a1cfccccac1ff03bc26f4b6",
            "id": null,
            "metadata": {},
            "name": "assignableToggleFieldDoneMutation",
            "operationKind": "mutation",
            "text": "mutation assignableToggleFieldDoneMutation(\n  $input: ToggleBlockFieldInput!\n) {\n  toggleBlockField(input: $input) {\n    ok\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = 'ade1200cfd8406ee63829db08b5cac21';
export default node;
