/**
 * As defined in our Semantic global variables
 * @deprecated
 */
export const colors = {
	black: "#000000",
	white: "#ffffff",
	royal: {
		100: "#ebf0fc",
		200: "#D7E1FC",
		300: "#BDCEFF",
		400: "#9EB5FF",
		500: "#788EFF",
		600: "#576CF7",
		700: "#495EE6",
		800: "#3C4FC9",
		900: "#344694",
		1000: "#29356E",
		1100: "#1C254F",
		1200: "#121936",
	},
	grey: {
		100: "#F2F3F9",
		200: "#E4E9ED",
		300: "#D2DCE0",
		400: "#B4C4CC",
		500: "#9AABB2",
		600: "#7F9199",
		700: "#6A7980",
		800: "#526066",
		900: "#3B474D",
		1000: "#273238",
		1100: "#182226",
		1200: "#10161A",
	},
	peach: {
		100: "#ffeee6",
		200: "#FCE5D4",
		300: "#FCD6BB",
		400: "#FCC49D",
		500: "#FAAE78",
		600: "#F09656",
		700: "#DE8343",
		800: "#C77338",
		900: "#735845",
		1000: "#4C423A",
		1100: "#332E2B",
		1200: "#1F1D1C",
	},
	green: {
		100: "#E6FAF1",
		200: "#CEF5E4",
		300: "#B4EDD4",
		400: "#8AE6BE",
		500: "#55D59D",
		600: "#25B878",
		700: "#209E67",
		800: "#1B8556",
		900: "#206B55",
		1000: "#204D40",
		1100: "#18332D",
		1200: "#11241F",
	},
	orange: {
		100: "#FFF2E0",
		200: "#FFE4BF",
		300: "#FCD8A4",
		400: "#FFCA80",
		500: "#FCB653",
		600: "#F0A53C",
		700: "#DB8E21",
		800: "#AD7111",
		900: "#705B38",
		1000: "#4D412E",
		1100: "#363128",
		1200: "#29251F",
	},
	red: {
		100: "#FFEBEB",
		200: "#FFDBDC",
		300: "#FFBDBD",
		400: "#FAA5A5",
		500: "#FA8283",
		600: "#E05A5B",
		700: "#CC4142",
		800: "#AD3435",
		900: "#803B3E",
		1000: "#593235",
		1100: "#42292D",
		1200: "#291C20",
	},
	yellow: {
		100: "#FFF4D9",
		200: "#ffe9b2",
		300: "#FFDD8C",
		400: "#FFD766",
		500: "#FFCD40",
		600: "#F7BA02",
		700: "#E3A600",
		800: "#D19600",
		900: "#725D28",
		1000: "#4E462B",
		1100: "#33311C",
		1200: "#21241A",
	},
	teal: {
		100: "#e1faf4",
		200: "#c1f2e6",
		400: "#8debd8",
		600: "#00d5b6",
		700: "#00bda1",
	},
	purple: {
		100: "#ECEBFF",
		200: "#E2E0FF",
		300: "#D3CCFC",
		400: "#C5B9FA",
		500: "#AD9BFA",
		600: "#9178FA",
		700: "#795AFA",
		800: "#6347D1",
		900: "#4A4096",
		1000: "#3B3473",
		1100: "#2A2952",
		1200: "#1B2037",
	},
	pink: {
		100: "#ffebf0",
		200: "#ffd1dd",
		400: "#ff8ca9",
		600: "#ea5278",
		700: "#d1345b",
	},
};

export enum NoteHighlightColors {
	red = "hl-red",
	peach = "hl-peach",
	yellow = "hl-yellow",
	green = "hl-green",
	royal = "hl-royal",
	purple = "hl-purple",
}

export enum NoteFontColors {
	red = "font-red",
	peach = "font-peach",
	yellow = "font-yellow",
	green = "font-green",
	royal = "font-royal",
	purple = "font-purple",
}

export const NoteHighlightAndFontColorsToNames = {
	"hl-red": "Red",
	"hl-peach": "Peach",
	"hl-yellow": "Yellow",
	"hl-green": "Green",
	"hl-royal": "Blue",
	"hl-purple": "Purple",
	"font-red": "Red",
	"font-peach": "Peach",
	"font-yellow": "Yellow",
	"font-green": "Green",
	"font-royal": "Blue",
	"font-purple": "Purple",
};
