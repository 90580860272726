/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type BlockID = {
    id?: string | null | undefined;
    noteId?: string | null | undefined;
    blockKey?: string | null | undefined;
};
export type AssignableDropdownToggleBlocksFieldMutationVariables = {
    ids?: Array<BlockID> | null | undefined;
    value: boolean;
};
export type AssignableDropdownToggleBlocksFieldMutationResponse = {
    readonly toggleBlocksField: {
        readonly noteBlockEdges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly done: boolean;
                readonly wontDo: boolean;
            } | null;
        }>;
    } | null;
};
export type AssignableDropdownToggleBlocksFieldMutation = {
    readonly response: AssignableDropdownToggleBlocksFieldMutationResponse;
    readonly variables: AssignableDropdownToggleBlocksFieldMutationVariables;
};



/*
mutation AssignableDropdownToggleBlocksFieldMutation(
  $ids: [BlockID!]
  $value: Boolean!
) {
  toggleBlocksField(input: {ids: $ids, field: WONT_DO, value: $value}) {
    noteBlockEdges {
      node {
        id
        done
        wontDo
      }
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "ids"
        } as any,
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "value"
        } as any
    ], v1 = [
        {
            "alias": null,
            "args": [
                {
                    "fields": [
                        {
                            "kind": "Literal",
                            "name": "field",
                            "value": "WONT_DO"
                        },
                        {
                            "kind": "Variable",
                            "name": "ids",
                            "variableName": "ids"
                        },
                        {
                            "kind": "Variable",
                            "name": "value",
                            "variableName": "value"
                        }
                    ],
                    "kind": "ObjectValue",
                    "name": "input"
                }
            ],
            "concreteType": "ToggleBlocksFieldPayload",
            "kind": "LinkedField",
            "name": "toggleBlocksField",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "NoteBlockEdge",
                    "kind": "LinkedField",
                    "name": "noteBlockEdges",
                    "plural": true,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "NoteBlock",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "id",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "done",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "wontDo",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "storageKey": null
        } as any
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "AssignableDropdownToggleBlocksFieldMutation",
            "selections": (v1 /*: any*/),
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "AssignableDropdownToggleBlocksFieldMutation",
            "selections": (v1 /*: any*/)
        },
        "params": {
            "cacheID": "fe91539503a88524c53adeae98fdf476",
            "id": null,
            "metadata": {},
            "name": "AssignableDropdownToggleBlocksFieldMutation",
            "operationKind": "mutation",
            "text": "mutation AssignableDropdownToggleBlocksFieldMutation(\n  $ids: [BlockID!]\n  $value: Boolean!\n) {\n  toggleBlocksField(input: {ids: $ids, field: WONT_DO, value: $value}) {\n    noteBlockEdges {\n      node {\n        id\n        done\n        wontDo\n      }\n    }\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = '513e3f020ce607f1ea15a00af6dd4891';
export default node;
