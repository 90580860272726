/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type ToggleBlockFieldField = "DONE" | "WONT_DO";
export type ToggleBlockFieldInput = {
    noteBlockId?: string | null | undefined;
    noteId?: string | null | undefined;
    noteBlockKey?: string | null | undefined;
    field: ToggleBlockFieldField;
    value: boolean;
    clientMutationId?: string | null | undefined;
};
export type ActionItemsTabToggleCheckedMutationVariables = {
    input: ToggleBlockFieldInput;
};
export type ActionItemsTabToggleCheckedMutationResponse = {
    readonly toggleBlockField: {
        readonly ok: boolean;
        readonly noteBlockEdge: {
            readonly node: {
                readonly id: string;
                readonly done: boolean;
                readonly wontDo: boolean;
                readonly note: {
                    readonly stream: {
                        readonly openActionItemCount: number;
                    };
                };
                readonly originalBlock: {
                    readonly id: string;
                    readonly done: boolean;
                    readonly wontDo: boolean;
                    readonly childBlocks: {
                        readonly edges: ReadonlyArray<{
                            readonly node: {
                                readonly id: string;
                                readonly done: boolean;
                                readonly wontDo: boolean;
                            } | null;
                        } | null>;
                    };
                } | null;
                readonly childBlocks: {
                    readonly edges: ReadonlyArray<{
                        readonly node: {
                            readonly id: string;
                            readonly done: boolean;
                            readonly wontDo: boolean;
                        } | null;
                    } | null>;
                };
            } | null;
        } | null;
    } | null;
};
export type ActionItemsTabToggleCheckedMutation = {
    readonly response: ActionItemsTabToggleCheckedMutationResponse;
    readonly variables: ActionItemsTabToggleCheckedMutationVariables;
};



/*
mutation ActionItemsTabToggleCheckedMutation(
  $input: ToggleBlockFieldInput!
) {
  toggleBlockField(input: $input) {
    ok
    noteBlockEdge {
      node {
        id
        done
        wontDo
        note {
          __typename
          stream {
            __typename
            openActionItemCount
            id
          }
          id
        }
        originalBlock {
          id
          done
          wontDo
          childBlocks {
            edges {
              node {
                id
                done
                wontDo
              }
            }
          }
        }
        childBlocks {
          edges {
            node {
              id
              done
              wontDo
            }
          }
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "input"
        } as any
    ], v1 = [
        {
            "kind": "Variable",
            "name": "input",
            "variableName": "input"
        } as any
    ], v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
    } as any, v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any, v4 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "done",
        "storageKey": null
    } as any, v5 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "wontDo",
        "storageKey": null
    } as any, v6 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "openActionItemCount",
        "storageKey": null
    } as any, v7 = {
        "alias": null,
        "args": null,
        "concreteType": "NoteBlockConnection",
        "kind": "LinkedField",
        "name": "childBlocks",
        "plural": false,
        "selections": [
            {
                "alias": null,
                "args": null,
                "concreteType": "NoteBlockEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "NoteBlock",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                            (v3 /*: any*/),
                            (v4 /*: any*/),
                            (v5 /*: any*/)
                        ],
                        "storageKey": null
                    }
                ],
                "storageKey": null
            }
        ],
        "storageKey": null
    } as any, v8 = {
        "alias": null,
        "args": null,
        "concreteType": "NoteBlock",
        "kind": "LinkedField",
        "name": "originalBlock",
        "plural": false,
        "selections": [
            (v3 /*: any*/),
            (v4 /*: any*/),
            (v5 /*: any*/),
            (v7 /*: any*/)
        ],
        "storageKey": null
    } as any, v9 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
    } as any;
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "ActionItemsTabToggleCheckedMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "ToggleBlockFieldPayload",
                    "kind": "LinkedField",
                    "name": "toggleBlockField",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "NoteBlockEdge",
                            "kind": "LinkedField",
                            "name": "noteBlockEdge",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "NoteBlock",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        (v3 /*: any*/),
                                        (v4 /*: any*/),
                                        (v5 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": null,
                                            "kind": "LinkedField",
                                            "name": "note",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": null,
                                                    "kind": "LinkedField",
                                                    "name": "stream",
                                                    "plural": false,
                                                    "selections": [
                                                        (v6 /*: any*/)
                                                    ],
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        },
                                        (v8 /*: any*/),
                                        (v7 /*: any*/)
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "ActionItemsTabToggleCheckedMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "ToggleBlockFieldPayload",
                    "kind": "LinkedField",
                    "name": "toggleBlockField",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "NoteBlockEdge",
                            "kind": "LinkedField",
                            "name": "noteBlockEdge",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "NoteBlock",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        (v3 /*: any*/),
                                        (v4 /*: any*/),
                                        (v5 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": null,
                                            "kind": "LinkedField",
                                            "name": "note",
                                            "plural": false,
                                            "selections": [
                                                (v9 /*: any*/),
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": null,
                                                    "kind": "LinkedField",
                                                    "name": "stream",
                                                    "plural": false,
                                                    "selections": [
                                                        (v9 /*: any*/),
                                                        (v6 /*: any*/),
                                                        (v3 /*: any*/)
                                                    ],
                                                    "storageKey": null
                                                },
                                                (v3 /*: any*/)
                                            ],
                                            "storageKey": null
                                        },
                                        (v8 /*: any*/),
                                        (v7 /*: any*/)
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "2cbf58e6a4307272a94cce42653eb7e3",
            "id": null,
            "metadata": {},
            "name": "ActionItemsTabToggleCheckedMutation",
            "operationKind": "mutation",
            "text": "mutation ActionItemsTabToggleCheckedMutation(\n  $input: ToggleBlockFieldInput!\n) {\n  toggleBlockField(input: $input) {\n    ok\n    noteBlockEdge {\n      node {\n        id\n        done\n        wontDo\n        note {\n          __typename\n          stream {\n            __typename\n            openActionItemCount\n            id\n          }\n          id\n        }\n        originalBlock {\n          id\n          done\n          wontDo\n          childBlocks {\n            edges {\n              node {\n                id\n                done\n                wontDo\n              }\n            }\n          }\n        }\n        childBlocks {\n          edges {\n            node {\n              id\n              done\n              wontDo\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = '97391bf2940bfb4c7ab119c2c748d927';
export default node;
